import React, {useState} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

import {buildImageObj, cn, toPlainText} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import {colors, font, fontfamily} from '../styles/variables'

import Heading from './heading'
import Button from './button'
import {elementType} from 'prop-types'
import WorkshopsPage from '../pages/workshops'

const Wrapper = styled.section`
  display: block;
  padding: 4rem 0;
  margin: 0 1.6rem;
  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 680px;
  }
`

const ImageWrapper = styled.div`
  display: block;
  margin: 0 2.4rem 4rem 2.4rem;
`

const Image = styled.img`
  display: block;
  border-radius: 5px;
`

const Text = styled.div`
  margin: 0 0 5.6rem 0;
`

const Search = styled.div`
  display: block;
  margin: 0 0 4rem 0;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

`

const Class = styled.div`
  display: block;
  margin: 0 0 4rem 0;
  @media (min-width: 1024px) {
    flex: auto  0 1;
    margin: 0;
  }
`

const Location = styled.div`
  display: block;
  @media (min-width: 1024px) {
    flex: auto  0 1;
  }
`

const Label = styled.span`
  display: block;
  font-family : ${fontfamily.jaRounded};
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 0 0.8rem 0;
  line-height: 1;
`

const List = styled.ul`
  display: block;
`

const Item = styled.li`
  display: block;
  font-size: 1.6rem;
  margin: 0 0 0.8rem 0;
`

const Result = styled.div`
  display: block;
`

const CheckBox = styled.input`
  padding: 0 0 0 2rem;
`

const Counter = styled.span`
  font-size: 1.8rem;
  font-weight: 500;
  font-family: ${fontfamily.jaRounded};
  margin-bottom: 2.4rem;
  display: block;
`

const Count = styled.span`
  font-size: 2.0rem;
  font-weight: 700;
  margin-right: 0.2rem;
`

const ResultItem = styled(props => <Link {...props} />)`
  display: block;
  padding: 1.2rem 0;
  margin: 1.2rem 0 ;
  border-bottom: 1px solid ${colors.darkGray};
  text-decoration: none;
  color: ${colors.black}
`

const Title = styled.span`
  font-size: 1.8rem;
  font-weight: 500;
  margin-right: 0.2rem;
  display: block;
  color: ${colors.Navy};
`

const GradeLocaiton = styled.span`
  font-size: 1.4rem;
  margin-right: 0.2rem;
  display: block;
  opacity: 0.6;
`

const DummyLink = styled.span`
  font-size: 1.4rem;
  color: ${colors.blue};
`

const OneLineDesc = styled.span`
  font-size: 1.4rem;
  color: ${colors.navy};
  display: block;
`

class WorkshopsArchive extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: false,
      result: this.props.nodes,
      counter: this.props.nodes.length,
      grades: [],
      locations: [],
      isPreschool: false,
      isElementarySchool: false,
      isMidSchool: false,
      isHighSchool: false,
      isOthers: false,
      isEnglewoodCliffs: false,
      isManhattan: false,
      isFortLee: false,
      isPortWashington: false
    }
    this.gradesDic = {
      'pre-school': '幼児部',
      'elementary-school': '小学部',
      'mid-school': '中学部',
      'high-school': '高等部',
      'others': 'その他(大人向けなど)'
    }
    this.locationsDic = {
      'manhattan': 'マンハッタン',
      'fort-lee': 'フォート・リー',
      'port-washington': 'ポート・ワシントン',
      'englewood-cliffs': 'イングルウッド・クリフス'
    }

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange (e) {
    let result = []
    let _grades = []
    let _locations = []
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    }, () => {
      if (this.state.isPreschool) {
        _grades.push('pre-school')
      }
      if (this.state.isElementarySchool) {
        _grades.push('elementary-school')
      }
      if (this.state.isMidSchool) {
        _grades.push('mid-school')
      }
      if (this.state.isHighSchool) {
        _grades.push('high-school')
      }
      if (this.state.isOthers) {
        _grades.push('others')
      }
      if (this.state.isEnglewoodCliffs) {
        _locations.push('englewood-cliffs')
      }
      if (this.state.isManhattan) {
        _locations.push('manhattan')
      }
      if (this.state.isFortLee) {
        _locations.push('fort-lee')
      }
      if (this.state.isPortWashington) {
        _locations.push('port-washington')
      }
      // this.setState({
      //   grades: _grades,
      //   locations: _locations
      // }, () => {
      this.props.nodes.forEach((el) => {
        if (_grades.length > 0 && _locations.length > 0) {
          for (const i of _grades) {
            for (const j of _locations) {
              if (el.grade.includes(i) && el.location.includes(j)) { result.push(el) }
            }
          }
        } else {
          for (const i of _grades) {
            if (el.grade.includes(i)) { result.push(el) }
          }
          for (const i of _locations) {
            if (el.location.includes(i)) { result.push(el) }
          }
        }
        const unique = [...new Set(result)]
        if (unique.length > 0) {
          this.setState({
            result: unique,
            counter: unique.length
          })
        } else {
          if (_grades.length > 0 || _locations.length > 0) {
            this.setState({
              result: [],
              counter: 0
            })
          } else {
            this.setState({
              result: this.props.nodes,
              counter: this.props.nodes.length
            })
          }
        }
      })
    })
    // })
  }

  render () {
    return (
      <Wrapper>
        <Heading title='語学講座・大人向け講座など' titleEn='Workshops' />
        <Text>
          {this.props.workshopsPage && this.props.workshopsPage._rawBody &&
            <PortableText blocks={this.props.workshopsPage._rawBody} />
          }
        </Text>
        <Search>
          <Class>
            <Label>クラス</Label>
            <List>
              <Item>
                <label className='form-check-label'>
                  <CheckBox
                    type='checkbox'
                    data-group='grades'
                    name='isPreschool'
                    defaultValue={this.state.isPreschool}
                    onChange={this.handleInputChange}
                  />
                幼児部
                </label>
              </Item>
              <Item>
                <label className='form-check-label'>
                  <CheckBox
                    type='checkbox'
                    data-group='grades'
                    name='isElementarySchool'
                    defaultValue={this.state.isElementarySchool}
                    onChange={this.handleInputChange}
                  />
              小学部
                </label>
              </Item>
              <Item>
                <label className='form-check-label'>
                  <CheckBox
                    type='checkbox'
                    data-group='grades'
                    name='isMidSchool'
                    defaultValue={this.state.isMidSchool}
                    onChange={this.handleInputChange}
                  />
              中学部
                </label>
              </Item>
              <Item>
                <label className='form-check-label'>
                  <CheckBox
                    type='checkbox'
                    data-group='grades'
                    name='isHighSchool'
                    defaultValue={this.state.isHighSchool}
                    onChange={this.handleInputChange}
                  />
              高等部
                </label>
              </Item>
              <Item>
                <label className='form-check-label'>
                  <CheckBox
                    type='checkbox'
                    data-group='grades'
                    name='isOthers'
                    defaultValue={this.state.isOthers}
                    onChange={this.handleInputChange}
                  />
              その他(大人向けなど)
                </label>
              </Item>
            </List>
          </Class>
          <Location>
            <Label>エリア</Label>
            <List>
              <Item>
                <label className='form-check-label'>
                  <CheckBox
                    type='checkbox'
                    name='isEnglewoodCliffs'
                    defaultValue={this.state.isEnglewoodCliffs}
                    onChange={this.handleInputChange}
                  />
                イングルウッド・クリフス(NJ)
                </label>
              </Item>
              <Item>
                <label className='form-check-label'>
                  <CheckBox
                    type='checkbox'
                    name='isManhattan'
                    defaultValue={this.state.isManhattan}
                    onChange={this.handleInputChange}
                  />
              マンハッタン
                </label>
              </Item>
              <Item>
                <label className='form-check-label'>
                  <CheckBox
                    type='checkbox'
                    name='isFortLee'
                    defaultValue={this.state.isFortLee}
                    onChange={this.handleInputChange}
                  />
              フォート・リー(NJ)
                </label>
              </Item>
              <Item>
                <label className='form-check-label'>
                  <CheckBox
                    type='checkbox'
                    name='isPortWashington'
                    defaultValue={this.state.isPortWashington}
                    onChange={this.handleInputChange}
                  />
              ポート・ワシントン(NY)
                </label>
              </Item>
            </List>
          </Location>
        </Search>
        <Result>
          <Counter><Count>{this.state.counter}</Count>件見つかりました。</Counter>
          <List>
            {this.state.result &&
          this.state.result.map(node => (
            <ResultItem key={node._id} to={`/workshops/${node.slug.current}/`}>
              <Title>{node.title}</Title>
              {/* <GradeLocaiton>
                {this.locationsDic[node.location]}
              </GradeLocaiton> */}
              <OneLineDesc>{node.oneLine}</OneLineDesc>
              <DummyLink >
                詳細を見る >
              </DummyLink>
            </ResultItem>
          ))
            }
          </List>
        </Result>
      </Wrapper>
    )
  }
}

export default WorkshopsArchive
